import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useMount from 'hooks/useMount';
import api from 'helpers/api';

import { ConfigContext } from '../../App';

import './index.scss';

const AccountsHeader = () => {
  const history = useHistory();

  const [state, setState] = useState({
    email: '',
    fileUrl: '',
    fullName: '',
    loggedOutClick: false,
    isOpenedProfile: false,
    isOpenedPopover: false,
  });

  useMount(() => {
    api.getList('/users/me').then(response => {
      response &&
      setState(prev => (
        {
          ...prev,
          fileUrl: response.avatarUrl ? `${process.env.REACT_APP_ENDPOINT}/${response.avatarUrl}` : null,
          email: response.email || '',
          fullName: `${response.firstName || ''} ${response.lastName || ''}`,
        }
      ));
    });
    document.addEventListener('avatarChanged', ({ detail: { fileUrl } = {} }) => setState(prev => ({ ...prev, fileUrl })));
    document.addEventListener('profileNameChanged', ({ detail: { fullName } = {} }) => setState(prev => ({ ...prev, fullName })));
  });

  useMount(() => {
    // third party code segment
      var script = document.createElement('script');
      script.async = 1;
      const { host, protocol } = window.location;

      script.src = `${protocol}//${host}/applications/index.js`;
      script.id = 'applicationsScript';
      document.head.appendChild(script);

      window.ssoApplications = function(key, value) {
        window.ssoApplications.configs = window.ssoApplications.configs || {};
        window.ssoApplications.configs[key] = value;
      };

      window.ssoApplications('options', {
        insertBefore: document.getElementById('container'),
      });
      window.ssoApplications('anchorElement',  'sso-applications-container');

  });

  const gotoMain = () => history.push('/');

  const gotoAccount = () => history.push('/profile');

  const handleProfileClick = () => setState(prev => ({ ...prev, isOpenedProfile: !state.isOpenedProfile }));

  const onProfileBackDropClick = () => setState(prev => ({ ...prev, isOpenedProfile: false }));

  const hideAccountHeaderOnLogoutClick = () => {
    setState(prev => ({ ...prev, loggedOutClick: true }))
  };

  const profileLogout = () => {
    hideAccountHeaderOnLogoutClick();
    const node = document.getElementById('applicationsScript');

    if (node.parentNode) {
      node.parentNode?.removeChild(node);
    }
    history.push('/signout', { force: true });
  };

  const profileImage = state.fileUrl ? { backgroundImage: `url(${state.fileUrl})`, } : {};

  const config = useContext(ConfigContext);

  return (
    !state.loggedOutClick &&
    <header>
      <ul className="guide">
        <li>
          {config.name.toLowerCase() === 'betconstruct' ? (
            <div className="icon-logo-account" onClick={gotoMain} />
          ) : (
            <div className="letter-logo-account" onClick={gotoMain}>
              {config.name}
            </div>
          )}
        </li>
        <li id="sso-applications-container" className="header-actions">
          <hr id="container" />
          <div className="drop-holder" onClick={handleProfileClick}>
            <div className="drop-icon icon-user" style={profileImage}/>
            {state.isOpenedProfile && <div className="drop-backdrop" onClick={onProfileBackDropClick} />}
            <div className={`drop-content-holder ${state.isOpenedProfile ? 'opened' : ''}`}>
              <div className="profile-i-holder">
                <ul className="user-profile-info" onClick={gotoAccount}>
                  <li className="drop-icon icon-user big" style={profileImage}/>
                  <li>
                    <h5 className="ellipsis-text">{state.fullName}</h5>
                    <h6 className="ellipsis-text">{state.email}</h6>
                  </li>
                </ul>
                <button onClick={profileLogout}>Logout</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </header>
  );
};

export default AccountsHeader;
